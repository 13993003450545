import { mapGetters, mapActions, mapMutations } from 'vuex'
import comLayout from './layout.vue'

export default {
  components: { comLayout },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('product/partner', ['cache', 'cacheFormRule', 'saleDateRange', 'writeOffDateRange']),
    isAdd() {
      if (this.$route && this.$route.params && this.$route.params.id && this.$route.params.id === '0') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations('product/partner', ['updateCache', 'clearCache']),
    ...mapActions('product/partner', ['getData', 'handleNext', 'submit']),

    handleSubmit() {
      const time = new Date(new Date().toLocaleDateString()).getTime() + 3600 * 1000 * 24 * 2 - 1
      let arr = []
      let isNext = false
      const h = this.$createElement
      if (!this.saleDateRange || this.saleDateRange.length !== 2) {
        this.$message.error('售卖时间不能为空')
        return
      }
      if (this.saleDateRange[1] < time) {
        isNext = true
        arr.push(h('div', { style: `color: #e71414` }, '商品售卖结束时间早于明日,是否提交?'))
      }
      if (this.writeOffDateRange[1] < time) {
        isNext = true
        arr.push(h('div', { style: `color: #e71414` }, '商品核销结束时间早于明日,是否提交?'))
        console.log(13)
      }
      if (isNext) {
        console.log(1)
        this.$msgbox({
          title: '提示',
          message: h('p', null, arr),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              console.log(132)
              this.nextSubmit()
            }
            done()
          }
        })
      } else {
        console.log(14)
        this.nextSubmit()
      }
    },
    nextSubmit() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.submit({ copy: Boolean(this.$route.query.copy) })
        .then(res => {
          this.$message.success(this.$route.query.copy ? '复制成功' : this.isAdd ? '添加成功' : '编辑成功')

          if (this.cache.saleType === 1) {
            this.$router.push('/product/partnerProduct/normalProductlist/list')
          } else if (this.cache.saleType === 2) {
            this.$router.push('/product/partnerProduct/list')
          } else if (this.cache.saleType === 3) {
            this.$router.push('/product/partnerProduct/codelessProduct/list')
          } else if (this.cache.saleType === 4) {
            this.$router.push('/product/partnerProduct/seckillProductList/list')
          } else if (this.cache.saleType === 5) {
            this.$router.push('/product/partnerProduct/expressProductList/list')
          }
          this.clearCache()
          this.$store.commit('product/partner/productStoreList', [])
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      ![
        'product-partnerProduct-id-basic',
        'product-partnerProduct-id-detail',
        'product-partnerProduct-id-extend'
      ].includes(to.name)
    ) {
      this.clearCache()
      this.$store.commit('product/partner/productStoreList', [])
      next()
    } else {
      next()
    }
  }
}
