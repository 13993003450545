<!--
 * @Author: Link
 * @Date: 2021-01-12 14:48:35
 * @LastEditTime: 2021-03-23 14:48:41
-->
<template>
  <div class="preview">
    <img src="@/asset/phone.png" class="bg" />
    <div class="panel">
      <el-carousel class="swiper">
        <el-carousel-item v-for="item in productInfo.productMaterialsList" :key="item.id">
          <img :src="item.fileUrl | thumbMedium" draggable="false" />
        </el-carousel-item>
      </el-carousel>
      <div class="content">
        <div class="product-info">
          <div class="info">
            <div class="price" v-if="productInfo.skuList && productInfo.skuList.length">
              <div class="now-price">￥{{ productInfo.skuList[0].salePrice }}</div>
              <div class="old-price">￥{{ productInfo.skuList[0].originalPrice }}</div>
            </div>
            <div class="sale">|</div>
          </div>
          <div class="name">{{ productInfo.productName }}</div>
          <div class="desc">{{ productInfo.productDescription }}</div>
          <div class="tags" ref="tags">
            <div
              class="tag"
              v-for="(item, index) in pickedServiceTagList"
              :key="item.id"
              :style="`text-align:${index === 0 ? 'left' : index === 1 ? 'center' : 'right'}`"
            >
              <i class="el-icon-warning-outline" style="margin-right:5px;"></i>{{ item.serviceContent }}
            </div>  
          </div>
        </div>
        <div class="shop-info">
          <div class="shop">
            <div class="logo">
              <img :src="businessInfo.logo | thumbMedium" v-if="businessInfo.logo" class="img" />
            </div>
            <div class="name">{{ businessInfo.name }}</div>
            <div class="btn">进店看看</div>
          </div>
        </div>
        <div class="notice block" ref="notice">
          <div class="title">购买须知</div>
          <div style=" word-break: break-all;" v-html="productInfo.purchaseInstructions"></div>
        </div>
        <div class="block" ref="detail">
          <div class="title">商品详情</div>
          <div style=" word-break: break-all;" v-html="productInfo.productDetailContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let scrollOpt = {
  block: 'start',
  behavior: 'smooth'
}
export default {
  props: {
    productInfo: Object,
    businessInfo: Object,
    pickedServiceTagList: Array
  },
  watch: {
    'productInfo.serviceDescriptionIds'() {
      scrollOpt.block = 'center'
      this.$refs.tags.scrollIntoView(scrollOpt)
    },
    'productInfo.purchaseInstructions'() {
      scrollOpt.block = 'start'
      this.$refs.notice.scrollIntoView(scrollOpt)
    },
    'productInfo.productDetailContent'() {
      scrollOpt.block = 'start'
      this.$refs.detail.scrollIntoView(scrollOpt)
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.preview {
  // position: fixed;
  // right: 0;
  // top: 0;
  position: relative;
  background: transparent;
  z-index: 999;
  height: fit-content;
  height: 864px;
  width: 400px;
  border-radius: 20px;
  overflow: hidden;
  user-select: none;
  transition: width 0.5s, height 0.5s, border-radius 0.8s;
  padding: 25px;
  scroll-behavior: smooth;
  .bg {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
  .topbar {
    position: absolute;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px 15px;
    .btn {
      float: right;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        filter: brightness(70%);
      }
    }
  }
  .panel {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 30px;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    /deep/.el-carousel__container {
      height: 100% !important;
    }
  }
  .swiper {
    height: 40%;
    width: 100%;
    overflow: hidden;
    position: sticky;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    z-index: 9;
    background: #f5f5f5;
    border-radius: 20px;
    overflow: hidden;
    .product-info {
      background: #fff;
      padding: 20px;
      text-align: left;
      .info {
        display: flex;
        align-items: center;
        .price {
          flex: 1;
          display: flex;
          align-items: center;
          .now-price {
            font-size: 20px;
            color: #e7141a;
            font-weight: bold;
            margin-right: 5px;
          }
          .old-price {
            font-size: 10px;
            text-decoration: line-through;
            color: #999;
            margin-top: 5px;
          }
        }
        .sale {
          background: #f7f7f7;
          border-radius: 30px;
          width: 100px;
          text-align: center;
          color: #f9f9f9;
          padding: 3px;
        }
      }
      .name {
        font-size: 20px;
        color: #333;
        margin-top: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .desc {
        font-size: 10px;
        color: #999;
        margin: 10px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tags {
        margin-top: 20px;
        font-size: 10px;
        color: #333;
        display: flex;
        justify-content: space-between;
        .tag {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .shop-info {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
      .shop {
        display: flex;
        align-items: center;
        .logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #f7f7f7;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          flex: 1;
          font-size: 20px;
          color: #333;
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          overflow: hidden;
          background: #f7f7f7;
          color: transparent;
          border-radius: 30px;
          padding: 7px 20px;
          font-size: 15px;
        }
      }
    }
    .block {
      margin-top: 20px;
      background: #fff;
      padding: 20px;

      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
  /deep/img {
    max-width: 100% !important;
  }
}
</style>
