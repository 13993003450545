<template>
  <div class="layoutContainer">
    <bm-cardTabList
      :list="[
        {
          path: `/product/partnerProduct/${$route.params.id}/basic?copy=${$route.query.copy || ''}`,
          name: '基本信息'
        },
        {
          path: `/product/partnerProduct/${$route.params.id}/detail?copy=${$route.query.copy || ''}`,
          name: '详情内容'
        },
        {
          path: `/product/partnerProduct/${$route.params.id}/extend?copy=${$route.query.copy || ''}`,
          name: '运营设定'
        }
      ]"
      :current="currentTab"
    ></bm-cardTabList>
    <div class="innerContainer">
      <div class="innerPage">
        <el-alert :title="tipText" type="success" :closable="false"> </el-alert>
        <slot></slot>
      </div>
      <div class="previewContainer">
        <comPreview
          :productInfo="$store.getters['product/partner/cache']"
          :businessInfo="$store.getters['product/partner/businessInfo']"
          :pickedServiceTagList="$store.getters['product/partner/pickedServiceTagList']"
        />
      </div>
    </div>

    <el-dialog
      title="选择商家"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
      :visible.sync="isShowBusinessPicker"
    >
      <div class="businessContainer">
        <div class="searchContainer">
          <el-input
            placeholder="请输入要搜索的商家名称"
            :value="businessListPage.searchValue"
            @input="updateBusinessListSearchValue"
            @keyup.enter.native="handleSearchBusinessList"
          ></el-input>
          <el-button
            @click="handleSearchBusinessList"
            type="success"
            plain
            icon="el-icon-search"
            style="margin-left: 10px"
          ></el-button>
        </div>
        <div
          class="businessItem"
          v-for="(i, n) in businessList"
          :key="n"
          :class="{ active: businessInfo.id === i.id }"
          @click="handlePickBusiness(i)"
        >
          <div class="logo" v-bg.cover="i.businessLogo"></div>
          <div class="title">{{ i.businessTitle }}</div>

          <div class="district">{{ i.areaIds | formatManyAreas }}</div>
          <div style="margin-left: 10px">
            <el-tag v-if="i.businessModel == 0">二级商户号</el-tag>
            <el-tag v-else type="success">普通收款</el-tag>
          </div>
        </div>
        <el-pagination
          style="text-align: right; margin-top: 10px"
          background
          layout="prev, pager, next"
          :total="businessListPage.total"
          :page-size="businessListPage.size"
          :current-page="businessListPage.page"
          @current-change="onBusinessListPageChange"
        ></el-pagination>
      </div>
      <span slot="footer">
        <el-button type="default" @click="handleGoto">取 消</el-button>
        <el-button type="primary" @click="handleGetStore" :disabled="!businessInfo.id">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import comPreview from '../../../__com__/preview'

export default {
  data: () => ({
    isShowBusinessPicker: false
  }),
  components: { comPreview },
  computed: {
    ...mapGetters('product/partner', ['businessInfo', 'cache', 'allowUpdateAll', 'isLoadWorkOrderDetail']),
    ...mapGetters({
      businessList: 'product/basic/businessList',
      businessListPage: 'product/basic/businessListPage'
    }),
    currentTab() {
      return {
        'product-partnerProduct-id-basic': '基本信息',
        'product-partnerProduct-id-detail': '详情内容',
        'product-partnerProduct-id-extend': '运营设定'
      }[this.$route.name]
    },
    isAdd() {
      if (this.$route && this.$route.params && this.$route.params.id === '0') {
        return true
      } else {
        return false
      }
    },
    isCopy() {
      return Boolean(this.$route.query.copy)
    },
    tipText() {
      if (this.$route && this.$route.params && this.$route.params.id === '0') {
        return '添加商品'
      } else if (!!this.$route.query.copy) {
        return '复制商品'
      } else {
        return '编辑商品'
      }
    },
    workOrderData() {
      return {
        name: this.$route.query.businessName,
        id: this.$route.query.id
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      updateBusinessListSearchValue: 'product/basic/updateBusinessListSearchValue',
      handlePickBusiness: 'product/partner/handlePickBusiness',
      update: 'product/basic/update',
      updateStatus: 'product/partner/update',
      updateSaleDateRange: 'product/partner/updateSaleDateRange',
      updateWriteOffDateRange: 'product/partner/updateWriteOffDateRange'
    }),
    ...mapActions({
      getBusinessList: 'product/basic/getBusinessList',
      getBookingRuleList: 'product/basic/getBookingRuleList',
      handleSearchBusinessList: 'product/basic/handleSearchBusinessList',
      onBusinessListPageChange: 'product/basic/onBusinessListPageChange',
      getStoreList: 'product/partner/getStoreList',
      getProductDetail: 'product/partner/getProductDetail',
      mallShareRatio: 'product/basic/mallShareRatio'
    }),
    handleGoto() {
      if (this.isCopy) {
        this.isShowBusinessPicker = false
        return
      }
      if (this.cache.saleType === 1) {
        this.$router.push('/product/partnerProduct/normalProductlist/list')
      } else if (this.cache.saleType === 2) {
        this.$router.push('/product/partnerProduct/list')
      } else if (this.cache.saleType === 3) {
        this.$router.push('/product/partnerProduct/codelessProduct/list')
      } else if (this.cache.saleType === 4) {
        this.$router.push('/product/partnerProduct/seckillProductList/list')
      } else if (this.cache.saleType === 5) {
        this.$router.push('/product/partnerProduct/expressProductList/list')
      }
    },
    handleGetStore() {
      let obj = this.allowUpdateAll ? { ...this.cache } : {}
      this.getStoreList(obj).then(res => {
        if (!res || !res.length) {
          this.$message.error('所选商家没有可用门店，请重新选择')
        } else {
          this.isShowBusinessPicker = false
          this.getBookingRuleList()
          console.log(this.businessInfo.businessModel)
          if (this.businessInfo.businessModel === 1) {
            this.update({ key: 'mallShareRatio', value: 100 })
          } else {
            this.mallShareRatio()
          }
        }
      })
    },
    getIssueDetail() {
      return this.$http
        .get('/boom-center-product-service/sysAdmin/oa/findCommodityDetail', {
          params: {
            commodityId: this.$route.query.cid
          }
        })
        .then(res => {
          let { commodity } = res
          this.updateStatus({ key: 'isLoadWorkOrderDetail', value: false })
          this.$store.commit('product/partner/update', {
            key: 'commodityData',
            value: commodity
          })
          let { date } = commodity
          if (date.saleStart && date.saleEnd)
            this.updateSaleDateRange([
              new Date(date.saleStart).getTime() - 2.88e7,
              new Date(date.saleEnd).getTime() - 2.88e7
            ])
          if (date.consumeStart && date.consumeEnd)
            this.updateWriteOffDateRange([
              new Date(date.consumeStart).getTime() - 2.88e7,
              new Date(date.consumeEnd).getTime() - 2.88e7
            ])
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  },
  created() {},
  mounted() {
    if (!this.isAdd && !this.isCopy) {
      if (this.$route.params.id !== this.cache.productId) {
        // 先查询工单信息(目的是为了获取套餐详情，但是此时会先将所有工单信息填入，再将商品详情填入)
        if (this.$route.query.cid) {
          this.getIssueDetail().then(_ => {
            this.getProductDetail({ id: this.$route.params.id, isCopy: this.isCopy })
          })
        } else {
          this.getProductDetail({ id: this.$route.params.id, isCopy: this.isCopy })
        }
      }
    } else {
      if (this.isCopy && Boolean(this.$route.query.getdata)) {
        if (this.$route.params.id !== this.cache.productId) {
          this.getProductDetail({ id: this.$route.params.id, isCopy: this.isCopy })
        }
      }
      if (!this.businessInfo.id) {
        this.$notify.info({ title: '提示', message: '请先选择商品所属商家' })
        this.isShowBusinessPicker = true
      }
      if (this.isAdd) {
        this.updateBusinessListSearchValue(this.workOrderData.name)
        if (this.$route.query.cid && !this.cache.oaCommodityId) {
          this.getIssueDetail()
          this.$store.commit('product/partner/updateCache', {
            key: 'oaCommodityId',
            value: this.$route.query.cid
          })
          this.$store.commit('product/partner/updateCache', {
            key: 'oaIssueId',
            value: this.$route.query.id
          })
        }
      }
      this.getBusinessList()
    }
  }
}
</script>

<style lang="less" scoped>
.innerContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  .innerPage {
    flex-grow: 1;
  }
  .previewContainer {
    margin-top: -40px;
    position: sticky;
    top: 10px;
    width: 450px;
    height: calc(~'100vh - 100px');
    margin-right: 10px;
    padding: 10px;
  }
}
.businessContainer {
  margin: -30px 0;
  .searchContainer {
    display: flex;
    align-items: center;
  }
  .businessItem {
    .pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    &:hover {
      background: var(--background);
    }
    &.active {
      background: lightblue;
      color: white;
    }
    .logo {
      .square(50px);
    }
    .title {
      margin: 0 10px;
      font-size: 18px;
    }
    .district {
      color: var(--grey);
      font-size: 12px;
    }
  }
}
</style>
